import React from 'react';
import { useLocation, Redirect, Route } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ children, ...rest }) => {
    const { auth } = useAuth();
    // const location = useLocation();

    console.log("== In here RequireAuth ======");
    console.log(auth);
    console.log(rest);
    // console.log(auth?.user?.roles.find(role => rest.allowedRoles?.includes(role)));

    console.log(rest?.allowedRoles);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth?.user?.roles.find(role => rest?.allowedRoles?.includes(role)) ? (
                    children
                ) : auth?.user ?
                    (
                        <Redirect
                            to={{
                                pathname: "/unauthorized",
                                state: { from: location }

                            }}
                        />
                    )
                    : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }

                            }}
                        />
                    )
            }
        />

        // auth?.user?.roles?.find(role => rest.allowedRoles?.includes(role))
        //     ? children
        //     : auth?.user
        //         ? <Redirect to="/unauthorized" state={{ from: location }} replace />
        //         : <Redirect to="/login" state={{ from: location }} replace />



    );
}

// const _RequireAuth = ({ component: Component, allowedRoles, ...rest }) => {
//     const { auth } = useAuth();
//     const location = useLocation();
//     console.log("== In here RequireAuth New ======");
//     console.log(auth);
//     console.log(rest);
//     console.log(allowedRoles);

//     return (
//         <Route
//             {...rest}
//             render={props => {
//                 return auth?.user?.roles.find(role => allowedRoles?.includes(role)) ? (
//                     <Component {...props} />
//                 ) : auth?.user ?
//                     (
//                         <Redirect
//                             to={{
//                                 pathname: "/unauthorized",
//                                 state: { from: location }

//                             }}
//                         />
//                     )
//                     : (
//                         <Redirect
//                             to={{
//                                 pathname: "/login",
//                                 state: { from: location }

//                             }}
//                         />
//                     )
//             }
//             }
//         />
//     );
// }


export default RequireAuth;