import http from "./config.service";

class AuthService {

    login(data) {

        // return new Promise((resolve, reject) => {
        //     setTimeout(function () {
        //         localStorage.setItem("user", JSON.stringify(data));
        //         resolve("¡Éxito!"); // ¡Todo salió bien!
        //     }, 250);
        // });

        return http.post("/auth/admin/signin", data).then(response => {

            if (response.data.status === 1) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }

            return response.data;
        })
    }

    logout() {
        localStorage.removeItem("user");
    }

    register() {

    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));;
    }

}

export default new AuthService();