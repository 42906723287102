import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Spinner from './componets/shared/Spinner';
import RequireAuth from './helpers/auth.helper';
import { AuthProvider } from './context/AuthProvider';

const Login = lazy(() => import('./componets/user-pages/Login'));

const Dashboard = lazy(() => import('./componets/dashboard/Dashboard'));

const MasksList = lazy(() => import('./componets/masks/MasksList'));
const Masks = lazy(() => import('./componets/masks/Mask'));

const Error404 = lazy(() => import('./componets/error-pages/Error404'));
const Unauthorized = lazy(() => import('./componets/error-pages/Unauthorized'));

const ROLES = {
  'Admin': 100,
  'User': 200
}

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>

      <Switch>

        <Route path="/login">
          <Login />
        </Route>

        <Route path="/unauthorized">
          <Unauthorized />
        </Route>

        <RequireAuth exact path="/" allowedRoles={[ROLES.Admin, ROLES.User]}>
          <Dashboard />
        </RequireAuth>

        <RequireAuth exact path="/dashboard" allowedRoles={[ROLES.Admin, ROLES.User]}>
          <Dashboard />
        </RequireAuth>

        <RequireAuth exact path="/masks/add" allowedRoles={[ROLES.Admin]}>
          <Masks />
        </RequireAuth>

        <RequireAuth exact path="/masks/edit/:id" allowedRoles={[ROLES.Admin]}>
          <Masks />
        </RequireAuth>
        
        <RequireAuth exact path="/masks" allowedRoles={[ROLES.Admin]}>
          <MasksList />
        </RequireAuth>

       

        <RequireAuth path="/foo" allowedRoles={[2]}>
          <MasksList />
        </RequireAuth>

      </Switch>

    </Suspense>

  );
}

export default AppRoutes