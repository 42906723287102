import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter  } from 'react-router-dom';
import App from './cms/App';
import "./i18n";
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './cms/context/AuthProvider';

ReactDOM.render(
  <BrowserRouter basename="/">
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
  , document.getElementById('root'));

serviceWorker.unregister();